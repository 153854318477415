<template>
  <router-link to="/account/login"
    ><img src="../../../assets/images/ommed_logo.svg" class="logo"
  /></router-link>
  <div class="account-form info-form fadeinleft animation-duration-300">
    <div v-if="changePassword" class="form infos fadeinleft animation-duration-300">
      <div class="card-title">Troca de senha</div>
      <div class="card-body my-4 d-flex jc-center fd-column textalign-c">
        <div class="sms-text mb-3">Digite sua nova senha e confirme.</div>
        <Password
          :feedback="false"
          class="std-input-up"
          v-model="v$.password.$model"
          placeholder="Senha"
          toggleMask
          maxlength="50"
          onpaste="return false" 
          ondrop="return false"
        />
        <Password
          :feedback="false"
          class="std-input-down"
          v-model="v$.confirm.$model"
          placeholder="Confirmar senha"
          toggleMask
          maxlength="50"
          onpaste="return false" 
          ondrop="return false"
        />
        <div class="invalid-error">
          <small class="p-invalid p-mt-2" v-if="!isValid">
            <i class="pi pi-times-circle" />Senhas não são iguais.
          </small>
        </div>
        <!-- <small
          class="p-invalid p-mt-2"
          v-if="
            (v$.password.$invalid && v$.password.$dirty) ||
            (v$.confirm.$invalid && v$.confirm.$dirty)
          "
        >
          <i class="pi pi-times-circle" />Utilize pelo menos oito caracteres.
        </small> -->

        <div class="mb-3 progress-container">
          <k-progress
            class="k-progress progress"
            :percent="strength.valor"
            :cut-width="2"
            :show-text="false"
            :color="strength.cor"
            :line-height="8"
          ></k-progress>
          <div class="mt-1 textalign-l">Força da senha</div>
        </div>
        <div class="button-change">
          <Button
            :disabled="!isFilled"
            class="std-button p-mt-6"
            label="Confirmar"
            @click="updatePassword"
          ></Button>
        </div>
      </div>
    </div>
    <div v-else class="form infos fadeinleft animation-duration-300">
      <div>
        <div class="card-title">Insira o código de segurança</div>
      </div>
      <div class="card-body my-4 d-flex jc-center fd-column textalign-c ai-center">
        Enviamos um código de confirmação para {{ user }}. <br />
        Digite o código que recebeu para confirmar:

        <form class="mt-4">
          <div class="sms-text">
            <InputMask
              class="input-code std-input"
              mask="999999"
              placeholder="XXXXXX"
              v-model="code"
              @blur="error = false"
            />
          </div>
          <div class="d-flex jc-center" style="margin: 10px">
            <small v-if="error" class="p-invalid p-mt-2"
              ><i class="pi pi-times-circle p-ml-1" /> Ops... confira o código de
              confirmação.</small
            >
          </div>
          <div class="sms-codetext p-mt-5">
            <a v-if="count < 0" class="m-3 std-button-text cursor-pointer font-bold" @click="resend()"><span>Reenviar</span></a>
            <span v-else>Seu código expira em ({{ count }})</span>
          </div>
          <!-- <div class="sms-codetext p-mt-5">
            <a class="m-3 std-button-text cursor-pointer font-bold" @click="resend()"
              ><span>Não recebeu um código?</span></a>
          </div> -->
          <div class="d-flex jc-center my-4">
            <Button
              :disabled="!isFilled"
              class="std-button p-mt-6"
              label="Confirmar"
              @click="verifyUser"
            ></Button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <Dialog
    :closable="false"
    v-model:visible="changedPassword"
    :style="{ width: '450px', borderRadius: '8px' }"
    :modal="true"
    class="modalSuccess"
  >
    <template #header>
      <h5 class="my-0 title-color">Acesso recuperado com sucesso</h5>
    </template>
    <p class="py-3 text-align-center">
      Sua senha foi atualizada <br />
      e agora você pode acessar a plataforma. <br /><br />
      Redirecionando...
    </p>
  </Dialog>
  <Dialog
    :closable="false"
    v-model:visible="resendDialog"
    :style="{ width: '320px', borderRadius: '15px' }"
    class="resend-change"
    :modal="true"
  >
    <template #header>
      <h5 class="my-0 title-color textalign-c">Atualização de senha</h5>
    </template>
    <div class="confirmation-content">
      <div class="text-center text-xl my-5 mx-5">
        <p>Reenviamos um código para {{ user }}</p>
      </div>
    </div>

    <template #footer>
      <Button
        class="p-button-text"
        label="Ok"
        @click="close"
        style="width: 55px; height: 36px"
      />
    </template>
  </Dialog>
</template>

<script>
import { ref, computed, onMounted, watchEffect } from "vue";
import router from "../../../router";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import {
  VerifyPasswordRecoveryCodeRequest,
  UpdatePasswordRequest,
  GetInfoProfessionalRequest,
  SendPasswordRecoveryCodeToUserRequest,
} from "../../core/grpc/generated/professionalAccount_pb";
import professionalAccountService from "../../core/services/professionalAccountService";

export default {
  name: "PhoneConfirmation",
  setup() {
    const count = ref(60);
    let user = ref(localStorage.getItem("user"));
    let userId = ref(localStorage.getItem("userId"));
    let data = ref("");
    let code = ref("");
    let professional = ref();
    let changePassword = ref(false);
    let changedPassword = ref(false);
    let password = ref("");
    let confirm = ref("");
    let error = ref(false);
    let token = ref("");
    let errorResponse = ref();
    let resendDialog = ref(false);
    let hasEmail = localStorage.getItem("email");
    let hasPhone = localStorage.getItem("phone");
    let strongPassword = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%^&*()]))|((?=.*[a-z])(?=.*[A-Z]))((?=.*[0-9])))(?=.{6,})"
    );
    let mediumPassword = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[^a-z])))(?=.{6,})"
    );

    const isFilled = computed(() => code.value.length == 6);

    onMounted(async() => {
      const request = new GetInfoProfessionalRequest();
      request.setId(userId.value);
      const response = await professionalAccountService.getInfoProfessional(request);
      console.log(response)
      if(response.success == true) {
        professional.value = JSON.parse(response.data);
        console.log(professional.value);
        if(hasPhone) {
        data.value = professional.value.phone;
      } else if(hasEmail) {
        data.value = professional.value.email;
      }
      await sendCodeRecovery();
      }
    })

    const verifyUser = async () => {
      const request = new VerifyPasswordRecoveryCodeRequest();
      request.setUser(data.value);
      request.setCode(code.value);
      // request.setUser(user.value);
      console.log("Request", request);
      const response = await professionalAccountService.verifyPasswordRecoveryCode(
        request
      );
      if (response.success == true) {
        // let result = JSON.parse(response.data);
        changePassword.value = true;
        token.value = JSON.parse(response.data).token;
        console.log(token.value[0]);
      } else {
        error.value = true;
        errorResponse.value = JSON.parse(response.data);
        console.log(errorResponse.value);
      }
    };

    const updatePassword = async () => {
      const request = new UpdatePasswordRequest();
      request.setPassword(password.value);
      request.setConfirm(confirm.value);
      request.setPasswordrecoverytoken(token.value[0]);
      const response = await professionalAccountService.updatePassword(request);
      if (response.success == true) {
        changedPassword.value = true;

        setTimeout(() => {
          router.push("/account/login");
        }, 5000);
      } else {
        error.value = true;
        errorResponse.value = JSON.parse(response.data);
      }
    };

    const redirectLogin = () => {
      router.push("/account/login");
    };

    // const close = () => {
    //   code.value = ["", "", "", "", "", ""];
    // };

    const close = () => {
      code.value = "";
      count.value = 60;
      resendDialog.value = false;
    };

    const resend = async () => {
      resendDialog.value = true;
      await sendCodeRecovery();
      // console.log(resendDialog);
    };

    watchEffect(() => {
      setInterval(() => {
        if (count.value >= 0) count.value--;
      }, 1000);
    });

    const sendCodeRecovery = async () => {
      let request = new SendPasswordRecoveryCodeToUserRequest();
      request.setUser(data.value);
      const response = await professionalAccountService.sendPasswordRecoveryCodeToUser(
        request
      );
      if (response.success == false) {
        errorResponse.value = JSON.parse(response.data);
        console.log(errorResponse.value);
      }
    };

    return {
      user,
      resend,
      changePassword,
      code,
      count,
      professional,
      isFilled,
      errorResponse,
      /*  isCode, */
      verifyUser,
      updatePassword,
      error,
      close,
      password,
      confirm,
      redirectLogin,
      resendDialog,
      changedPassword,
      v$: useVuelidate(),
      strength: computed(() => {
        let value = 0;
        let color = "";

        if (strongPassword.test(password.value)) {
          value = 100;
          color = "green";
        } else if (mediumPassword.test(password.value)) {
          value = 66;
          color = "yellow";
        } else if (password.value.length) {
          value = 33;
          color = "red";
        }
        let config = {
          valor: value,
          cor: color,
        };
        return config;
      }),
      isValid: computed(() => {
        if (confirm.value == password.value) {
          return true;
        }
        return false;
      }),
    };
  },
  validations() {
    return {
      password: { required, minLength: minLength(8) },
      confirm: { required, minLength: minLength(8) },
    };
  },
};
</script>

<style lang="scss">
.p-dialog {
  .p-dialog-header {
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }
  .p-dialog-footer {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    .p-button {
      width: 317px;
      height: 56px;
      margin: 0 auto;
      display: flex;
    }
  }
}

.resend-change {
  .p-dialog-header {
    display: flex !important;
    justify-content: center !important;
  }
}

.invalid-error {
  display: flex;
  margin: 8px 0;
}

.confirmation-content {
  display: flex;
  margin: 0 auto;
}

.progress {
  width: 32rem;
  margin: 0 auto;
}

.progress-container {
  width: 30rem;
}

.center-password {
  justify-content: center;
}
.updatePhone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-js-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.button-change {
  align-items: center;
  display: flex;
  margin: 0 auto;
}

.std-input-up {
  width: 100% !important;
  .p-password-input {
    height: 60px !important;
    border-radius: 8px 8px 0 0 !important;
    &:hover,
    &:focus {
      border: 1px solid #ff6a33 !important;
    }
  }
}

.std-input-down {
  width: 100% !important;
  .p-password-input {
    height: 60px !important;
    border-radius: 0 0 8px 8px !important;
    border-top: 1px solid white;
    &:hover,
    &:focus {
      border: 1px solid #ff6a33 !important;
    }
  }
}
.input-code {
  width: 200px !important;
  text-align: center !important;
  font-size: 20px;
}

.std-button-text {
  width: 200px !important;
  justify-content: center;
}

.std-button {
  width: 317px !important;
}

.modalSuccess .p-dialog-content {
  border-radius: 0px 0px 8px 8px;
}
</style>
